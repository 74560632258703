///coded by misa - компонент снекбара, который можно вызывать с любой страницы - сквозной
//юзаем так: импортируем:
//import SnackBar from "@/components / SnackBar.vue";
//import { useSnackBarStore } from "@/stores/snackbar.store";

//в js коде:
//const snackBarStore = useSnackBarStore()
//snackBarStore.error(error_msg)

//в template вставляем чайлда
//  <SnackBar />


import { defineStore } from "pinia";
const TIMEOUT = 3000

export const useSnackBarStore = defineStore({
    id: "snackBar",
    state: () => ({
        snackbar: {
            color: null,
            icon: "mdi-comment-remove-outline",
            text: null,
            timeout: TIMEOUT,
            title: null,
            visible: false
        },
    }),
    actions: {
        success(message) {
            this.snackbar = {
                color: "success",
                icon: "mdi-checkbox-marked-circle",
                timeout: TIMEOUT,
                title: "Success",
                text: message,
                visible: true,
            };
        },
        error(message) {
            this.snackbar = {
                color: "error",
                icon: "mdi-comment-remove-outline",
                timeout: TIMEOUT,
                title: "Error",
                text: message,
                visible: true
            };
        },
        clear() {
            this.snackbar.visible = false
            //console.log(this.snackbar)
        },
    },
});
