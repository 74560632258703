<template>
    <v-card>
        <v-toolbar color="primary" dense>
            <v-card-title class="pl-4">Редактирование</v-card-title>
            <v-spacer></v-spacer>
            <v-icon class="mr-4" @click="CloseDialog">mdi-close</v-icon>
        </v-toolbar>
        <v-card-text class="ma-1 pa-1">
            <v-container fluid class="ma-0 pa-0">
                <v-row>
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field color="primary" density="compact" :value="order.client" v-model="order.client"
                            label="Имя">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field color="primary" density="compact" :value="order.last_name" v-model="order.last_name"
                            label="Фамилия">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field color="primary" density="compact" :value="order.phone" v-model="order.phone"
                            label="Телефон">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field color="primary" density="compact" :value="order.city" v-model="order.city"
                            label="Город">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <select-box label="Доставка" :modelValue="order.deliv_type" :options="deliv_types"
                            @update:modelValue="delivTypesSelectUpd" />
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field color="primary" density="compact" :value="order.address" v-model="order.address"
                            label="Адрес" counter>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field color="primary" density="compact" :value="order.pvz_selected"
                            v-model="order.pvz_selected" label="ПВЗ">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field color="primary" disabled density="compact" type="number" :value="order.total_summ"
                            v-model="order.total_summ" label="Сумма заказа">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field color="primary" density="compact" type="number" v-model="order.ship_cost"
                            :value="order.ship_cost" label="Стоимость доставки">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field color="primary" density="compact" type="number" :value="order.discount"
                            v-model="order.discount" label="Скидка">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field color="primary" density="compact" type="email" v-model="order.email"
                            :value="order.email" label="e-mail">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field color="primary" density="compact" type="number" v-model="order.post_index"
                            :value="order.post_index" label="Индекс">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field color="primary" density="compact" v-model="order.track_number"
                            :value="order.track_number" counter label="Трек номер">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <select-box label="Способ оплаты" :modelValue="order.payment_method" :options="payment_methods"
                            @update:modelValue="paymentSelectUpd" />
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <select-box label="Продавец" :modelValue="order.seller_name" :options="user_list"
                            @update:modelValue="sellerSelectUpd" />
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <select-box label="Чек" :modelValue="order.isReceiptReq" :options="receipt_options"
                            @update:modelValue="receiptSelectUpd" />
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <div class="date-picker">
                            <div class="label">Дата закрытия</div>
                            <DatePicker v-model="order.closing_date" week-numbers auto-apply locale="ru"
                                :enable-time-picker="false" :format="Format" />
                        </div>
                    </v-col>

                </v-row>
            </v-container>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="CloseDialog">
                Close
            </v-btn>
            <v-btn color="success" text @click="Save">
                Save
            </v-btn>
        </v-card-actions>
    </v-card>
</template>


<script setup>
/* eslint-disable */
import { useOrderStore } from "@/stores/orders.store"
import DatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import { ref, onMounted, computed, } from "vue"
import { fetchWrapper } from '@/helpers/fetch-wrapper'
import SelectBox from "@/components/SelectBox.vue"

const props = defineProps({
    Order: Object,
})
const emit = defineEmits(['SaveOrder'])
const order = ref({})
const user_list = ref([])

onMounted(() => {
    order.value = props.Order
    GetUserList()
});
//для вызова из дочернего компонента SelectBox через Emit для изменения в родительском
const sellerSelectUpd = (val) => {
    order.value.seller_name = val.value
}
//для вызова из дочернего компонента SelectBox через Emit для изменения в родительском
const receiptSelectUpd = (val) => {
    order.value.isReceiptReq = val.value
}
//для вызова из дочернего компонента SelectBox через Emit для изменения в родительском
const paymentSelectUpd = (val) => {
    order.value.payment_method = val.value
}
//для вызова из дочернего компонента SelectBox через Emit для изменения в родительском
const delivTypesSelectUpd = (val) => {
    order.value.deliv_type = val.value
}

//опции для селект бокса
const deliv_types = ['Курьером по Москве', 'Самовывоз', 'СДЭК до ПВЗ', 'СДЭК до двери', 'Почта России', 'Шоурум']
const payment_methods = ['Наличные', 'Перевод на карту', 'Безналичная оплата', 'Смешанный']
const receipt_options = ['Да', 'Нет']

const GetUserList = async () => {
    user_list.value = await fetchWrapper.post(process.env.VUE_APP_API_USERS_URL, {
        action: "getSellerOperatorList",
    });
}

const Save = () => {
    const { updateOrder } = useOrderStore()
    updateOrder(order.value)
    //закрываем окно
    emit('SaveOrder')
}

const CloseDialog = () => {
    emit('SaveOrder')
}

const Format = (val) => {
    val.setHours(12, 0, 0, 0)
    val = val.toLocaleDateString("ru-RU")
    return val
}
</script>
<style>
.date-picker {
    margin-top: -18px;
}

.label {
    text-align: left;
    padding: 2px 0 4px 17px;
    color: dimgrey;
    margin: -4px;
    font-size: 12px;
    opacity: 0.6;
}
</style>