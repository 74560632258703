<template>
    <v-sheet>
        <v-toolbar color="primary" dense>
            <v-card-text class="text-xs-h6 text-md-h5 text-lg-h4 pl-4">
                <span class="d-none d-sm-flex">Состав заказа № {{ order_id }}</span>
            </v-card-text>
            <div :style="searchFieldWidth" v-show="add_product_is_show">
                <AutoComplete :options="options" dropdown_id="dropdown" placeholder="Артикул" v-on:selected="onSelect"
                    v-on:filter="getDropdownValues">
                </AutoComplete>
            </div>
            <v-spacer></v-spacer>
            <v-icon @click="add_product_is_show = !add_product_is_show" class="mr-4">mdi-magnify</v-icon>
            <v-icon class="mr-4" @click="CloseDialog(false)">mdi-close</v-icon>
        </v-toolbar>
        <div v-for="(val, index) in compos_list" :key="val">
            <v-sheet v-if="!val.isDeleted" class="pa-4">
                <v-card-text class="text-h6 text-md-h5 mb-4">
                    {{ compos_list.length > 1 ? index + 1 + '. ' : '' }}
                    {{ val.title }}
                </v-card-text>
                <v-row justify="start">
                    <v-col cols="12" sm="6" md="2"><a :href="val.full_link">
                            <v-img height="200" width="200" :src="val.img_link"></v-img>
                        </a>
                    </v-col>
                    <v-col cols="12" sm="6" md="2">
                        <div v-html="val.short_descr + '<br />Артикул: ' + val.vendor_code"></div>
                    </v-col>
                    <v-col cols="12" sm="6" md="2">
                        <SelectBox label="Размер" :id="index" :elemStyle="Colorer(val)" :modelValue="val.selected_size"
                            :options="val.sizes" @update:modelValue="SizesSelectUpd" />
                    </v-col>
                    <v-col cols="12" sm="6" md="2">
                        <v-text-field color="primary" disabled density="compact" type="number" :value="val.price"
                            v-model="val.price" label="Цена">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="2">
                        <v-text-field color="primary" density="compact" type="number" :value="val.vol" v-model="val.vol"
                            label="Количество" min="1" max="100">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="1">
                        <v-btn text @click="DeleteProduct(val.id, val.selected_size)" color="danger">Удалить</v-btn>
                    </v-col>
                </v-row>
            </v-sheet>
        </div>
        <v-row justify="end" class="ma-0">
            <v-col cols="12" md="4" align-self="center">
                <v-card-title>Сумма заказа: {{ total_summ }}р.</v-card-title>
            </v-col>
            <v-col cols="12" md="3" align-self="center">
                <v-btn class="ma-4" large elevation="4" rounded="lg" color="primary" @click="CloseDialog()">
                    Отмена
                </v-btn>
                <v-btn :disabled="!isValidAllFields" class="ma-4" large elevation="4" rounded="lg" color="success"
                    @click="Save()">
                    Сохранить
                </v-btn>
            </v-col>
        </v-row>
    </v-sheet>
    <confirm ref="confirm"></confirm>
</template>


<script>
import { fetchWrapper } from "@/helpers/fetch-wrapper"
import { useOrderStore } from "@/stores/orders.store"
import confirm from '@/components/ConfirmDialogYesNo.vue'
import AutoComplete from '@/components/AutoComplete.vue'
import SelectBox from "@/components/SelectBox.vue"

export default {
    name: "OrderCompos",
    components: {
        confirm, AutoComplete, SelectBox
    },
    props: {
        ComposList: Object,
    },
    mounted() {
        this.old_compos_list = this.ComposList.order_compos
        //console.log("ComposList: ")
        //console.log(this.compos_list)
        //чтобы элемент сделать нереактивным и неизменно хранить в нем предыдущие данные
        this.compos_list = JSON.parse(JSON.stringify(this.ComposList.order_compos));
        this.order_id = this.ComposList.id
    },
    data() {
        return {
            compos_list: [],
            order_id: '',
            old_compos_list: [],
            add_product_is_show: false,
            isLoading: false,
            items: [],
            model: null,
            options: [],
            selected: {}
        }
    },
    methods: {
        //для вызова из дочернего компонента SelectBox через Emit для изменения в родительском
        SizesSelectUpd(val) {
            if (val.props.id < this.compos_list.length) {
                this.compos_list[val.props.id].selected_size = val.value
            }
        },
        async onSelect(val) {
            await this.GetProductById(val.id)
        },
        async getDropdownValues(query) {
            if (query?.length > 0 && query?.length < 10) {
                this.options = await fetchWrapper.post(process.env.VUE_APP_API_ORDERS_URL, {
                    action: 'fastSearchProduct',
                    query: query
                });
            }
        },
        DeleteProduct(delete_id, selected_size) {
            //считаем не помеченные для удаления только
            let len = 0;
            this.compos_list.forEach(element => {
                if (!element.isDeleted) {
                    len++
                }
            });

            //если удалить надо последний товар в списке
            if (len == 1) {

                //выводим диалог с вопросом Надо ли удалить полностью весь заказ
                this.$refs.confirm.open('Удаление', 'Удалить целиком весь заказ?', { color: 'red' }).then((confirm) => {
                    //if yes
                    if (confirm) {
                        //console.log("fully deleted!")
                        //удаляем заказ целиком
                        const { updateOrderStatus } = useOrderStore()
                        updateOrderStatus('Отменен', this.order_id)
                        return
                        //if no
                    } else {
                        //удаляем только из этого списка пока
                        let index_for_del = -1;

                        for (let i = 0; i < this.compos_list.length; i++) {
                            if (this.compos_list[i].id == delete_id) {
                                if (this.compos_list[i].isNewProduct) {
                                    //если товар добавлен только в текущей сессии, то его не помечаем а удаляем из массива сразу
                                    index_for_del = i
                                    break
                                } else {
                                    //если товар добавлен был ранее, то его просто помечаем для удаления, 
                                    //чтоб потом в бекенде вернуть в стоки этот товар в наличие
                                    this.compos_list[i].isDeleted = true
                                    this.compos_list[i].isValid = false
                                    break
                                }
                            }
                        }
                        if (index_for_del > -1) {
                            this.compos_list.splice(index_for_del, 1)
                        }
                    }
                })
            } else {
                //удаляем только из этого списка, которые в текущем сеансе добавленные - удаляем из этого списка, 
                //а которые в базу записаны - помечаем на удаление шоб вернуть товар
                let index_for_del = -1;

                for (let i = 0; i < this.compos_list.length; i++) {
                    if (this.compos_list[i].id == delete_id && this.compos_list[i].selected_size == selected_size) {
                        if (this.compos_list[i].isNewProduct) {
                            //если товар добавлен только в текущей сессии, то его не помечаем а удаляем из массива сразу
                            index_for_del = i
                            break
                        } else {
                            //если товар добавлен был ранее, то его просто помечаем для удаления, 
                            //чтоб потом в бекенде вернуть в стоки этот товар
                            this.compos_list[i].isDeleted = true
                            break
                        }
                    }
                }
                if (index_for_del > -1) {
                    this.compos_list.splice(index_for_del, 1)
                }
            }
        },
        Save() {
            //const { loading } = storeToRefs(useOrderStore())
            const { updateComposOrder } = useOrderStore()
            updateComposOrder(this.compos_list, this.order_id, this.total_summ)
            this.$emit('UpdateComposList')

        },
        Colorer(val) {
            if (val.selected_size === undefined || val.selected_size === '') {
                val.isValid = false;
                return 'border-color: red'
            } else {
                val.isValid = true;
                return 'border-color: green'
            }
        },
        CloseDialog() {
            this.$emit('UpdateComposList')
        },
        async GetProductById(item_id) {
            item_id = parseInt(item_id);
            if (item_id > 0) {
                this.loading = true
                const product = await fetchWrapper.post(process.env.VUE_APP_API_ORDERS_URL, {
                    action: 'getProductById',
                    query: item_id
                });
                if (product?.title) {
                    //помечаем как добавленный в текущей сессии
                    product.isNewProduct = true
                    this.compos_list.unshift(product)
                    this.items = []
                }
                this.loading = false

            }
        },
    },
    computed: {
        isValidAllFields() {
            let res = true;
            this.compos_list.forEach(element => {
                if (!element.isValid) {
                    res = false
                }
            });
            return res

        },
        total_summ() {
            let total = 0;
            this.compos_list.forEach(item => {
                if (item.isDeleted !== true)
                    total += (item.price * item.vol)
            })
            return total
        },
        searchFieldWidth() {
            let width = 0;
            switch (this.$vuetify.display.name) {
                case 'xs':
                    width = 252
                    break
                case 'sm':
                    width = 400
                    break
                case 'md':
                    width = 500
                    break
                case 'lg':
                    width = 740
                    break
                case 'xl':
                    width = 900
                    break
            }
            const ret = `width: ${width}px`
            return ret
        }

    },
}
</script>
<style scope>
.selected-size {
    height: 67px;
}

#dropdown {
    position: fixed;
    z-index: 100;

}
</style>