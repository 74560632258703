<template>
  <v-row justify="center">
    <v-dialog v-model="dialog">
      <order-editor :Order="order" @SaveOrder="onSaveOrder()" />
    </v-dialog>
    <v-dialog v-model="compos" scrollable>
      <OrderCompos :ComposList="{ id: order.id, order_compos: order.total_order }"
        @UpdateComposList="onUpdateComposList()" />
    </v-dialog>
    <v-dialog v-model="isCommentDialogOpen" scrollable>
      <OrderComments :Comments="order.comments" :OrderId="order.id" @UpdateComments="onUpdateComments()" />
    </v-dialog>
  </v-row>
  <v-toolbar :color="ColorHandler(order.order_status)">
    <v-card-text>
      <div class="text-md-h4 text-sm-h6 text-xs-body-2">{{ order.id }}</div>
    </v-card-text>
    <v-card-text>
      <div class="text-sm-body-2 text-xs-body-2 text-md-body-1 text-center">
        {{ order.date }}
      </div>
    </v-card-text>
    <v-btn fab top right @click="dialog = !dialog">
      <v-icon v-if="dialog"> mdi-close </v-icon>
      <v-icon v-else> mdi-pencil </v-icon>
    </v-btn>
  </v-toolbar>
  <v-card density="comfortable" class="indigo lighten-5 pa-1">
    <v-container class="text-left">
      <v-select variant="solo" v-model="order.order_status" :items="order_statuses" item-title="name" item-value="name">
        <template v-slot:append>
          <v-btn @click="SaveStatus" :disabled="isDisabledSaveStatus" :loading="loading" size="x-small"
            icon="mdi-content-save">
          </v-btn>
        </template>
      </v-select>
      <v-list>
        <v-list-item>Имя Фамилия: {{ order.client }} {{ order.last_name }}</v-list-item>
        <v-list-item>Телефон: {{ order.phone }}</v-list-item>
        <v-list-item>Город: {{ order.city }}</v-list-item>
        <v-list-item>Способ доставки: {{ order.deliv_type }}</v-list-item>
        <v-list-item v-if="order.deliv_type == 'Почта России'">Индекс: {{ order.post_index }}</v-list-item>
        <v-list-item>Адрес/ПВЗ: {{ order.address }}/{{ order.pvz_selected }}</v-list-item>
        <v-list-item>Сумма заказа: {{ order.total_summ }}р.</v-list-item>
        <v-list-item>Стоимость доставки: {{ order.ship_cost }}р.</v-list-item>
        <v-list-item v-if="order?.track_number">Трекинг номер: {{ order.track_number }}</v-list-item>
        <v-list-item v-if="order?.closing_date">Закрыт:
          {{
            new Date(order.closing_date).toLocaleDateString("ru-RU")
          }}</v-list-item>
        <v-list-item v-if="order?.payment_method">Способ оплаты: {{ order.payment_method }}</v-list-item>
        <v-list-item v-if="order?.seller_name">Продавец: {{ order.seller_name }}</v-list-item>
      </v-list>

      <v-card-actions>
        <p class="text-uppercase text-h6">Состав</p>
        <v-btn fab top right @click="compos = !compos">
          <v-icon v-if="compos"> mdi-close </v-icon>
          <v-icon v-else> mdi-pencil </v-icon>
        </v-btn>
        <v-badge v-show="order_compos_length" color="#42a5f5" :content="order_compos_length" class="pa-0 mb-2">
        </v-badge>
        <v-spacer></v-spacer>
        <v-btn :icon="order_content_show ? 'mdi-chevron-up' : 'mdi-chevron-down'"
          @click="order_content_show = !order_content_show"></v-btn>
      </v-card-actions>
      <div v-for="val in order.total_order" :key="val">
        <v-sheet v-show="order_content_show">
          <v-expand-transition>
            <v-row no-gutters class="mt-2 text-center">
              <p class="text-h6 mb-2 w-100">{{ val.title }}</p>
              <v-col cols="12" md="4"><a :href="val.full_link">
                  <v-img height="200" width="200" :src="val.img_link"></v-img>
                </a>
              </v-col>
              <v-col>
                <div>{{ val.vendor_code }}</div>
              </v-col>
              <v-col>
                <div>{{ val.selected_size }}</div>
              </v-col>
              <v-col>
                <div>
                  <b>{{ val.price }}</b>
                </div>
              </v-col>
              <v-col>
                <div>{{ val.vol }}</div>
              </v-col>
            </v-row>
          </v-expand-transition>
        </v-sheet>
      </div>
      <v-card-actions>
        <p class="text-uppercase text-h6">Комментарии</p>
        <v-btn fab top right @click="isCommentDialogOpen = !isCommentDialogOpen" class="pl-0 mr-0">
          <v-icon v-if="isCommentDialogOpen"> mdi-close </v-icon>
          <v-icon v-else> mdi-pencil </v-icon>
        </v-btn>
        <v-badge v-show="comment_length" color="purple" :content="comment_length" class="pa-0 mb-2">
        </v-badge>
        <v-spacer></v-spacer>
        <v-btn :icon="isCommentsShow ? 'mdi-chevron-up' : 'mdi-chevron-down'"
          @click="isCommentsShow = !isCommentsShow"></v-btn>
      </v-card-actions>
      <div v-for="comment in order.comments" :key="comment.id">
        <div v-show="isCommentsShow">
          <v-expand-transition>
            <v-row no-gutters class="mt-2">
              <v-col cols="4" md="1">
                {{ comment.id }}
              </v-col>
              <v-col cols="8" md="3">
                {{ comment.date }}
              </v-col>
              <v-col cols="12" md="8" class="pl-4">
                {{ comment.text }}
              </v-col>
            </v-row>
          </v-expand-transition>
        </div>
      </div>
    </v-container>
  </v-card>
  <v-dialog v-model="close_order_dialog" color="deep-purple-accent-4">
    <v-row justify="center" align="center">
      <v-col cols="12" md="6">
        <v-card class="mx-auto">
          <v-toolbar color="deep-purple-lighten-1" class="text-h6 pl-2">
            <v-icon>mdi-alert</v-icon>
            <p class="pl-5">Укажите дополнительные данные</p>
          </v-toolbar>
          <v-card-text class="mt-4">
            <v-row justify="center">
              <v-col v-if="!isCancelOrder" cols="11">
                <select-box label="Способ оплаты" :modelValue="order.payment_method" :options="payment_methods"
                  @update:modelValue="paymentMethodSelectUpd" />
              </v-col>
              <v-col cols="11">
                <div class="label">Дата закрытия</div>
                <DatePicker v-model="order.closing_date" week-numbers locale="ru" :enable-time-picker="false"
                  :format="Format" inline auto-apply />
              </v-col>
              <v-col v-if="!isCancelOrder" cols="11">
                <v-switch v-model="is_sent_email_while_closing" hide-details inset color="deep-purple-accent-4"
                  :label="sent_email_while_closing_label" @update:modelValue="while_closing_sent_email_upd"></v-switch>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue-grey" variant="flat" @click="close_order_dialog = false">Отмена</v-btn>
            <v-btn color="success" variant="flat" @click="saveDialogStatus">Сохранить</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-dialog>
</template>

<script>
import { storeToRefs } from "pinia";
import { useOrderStore } from "@/stores/orders.store";
import OrderCompos from "@/components/OrderCompos.vue";
import OrderComments from "@/components/OrderComments.vue";
import OrderEditor from "@/components/OrderEditor.vue";
import DatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import SelectBox from "@/components/SelectBox.vue";

export default {
  name: "OrderCard",
  components: {
    OrderCompos,
    OrderComments,
    OrderEditor,
    DatePicker,
    SelectBox,
  },
  props: {
    oneOrder: Object,
  },
  mounted() {
    this.order = this.oneOrder;
    //сохраняем нереактивно первоначальные данные
    this.old_one_order = JSON.parse(JSON.stringify(this.oneOrder));
    //отсюда вызываем пинью т.к. она уже создалась
    const { isLoading } = storeToRefs(useOrderStore());
    this.loading = isLoading;

    if (parseInt(this.order.comments.length) > 0) {
      this.comment_length = this.order.comments.length;
    }
    this.order_compos_length = this.order.total_order.length;
  },
  data() {
    return {
      sent_email_while_closing_label: "Email о закрытии заказа шлём",
      is_sent_email_while_closing: true,
      close_order_dialog: false,
      isCancelOrder: false,
      isEditCompos: false,
      order_content_show: false,
      isCommentsShow: false,
      comment_length: "",
      order: [],
      order_compos_length: 0,
      dialog: false,
      compos: false,
      old_one_order: [],
      order_statuses: [
        { name: "Новый", color: "#B71C1C" },
        { name: "Сборка", color: "#FB8C00" },
        { name: "Замеры отправлены", color: "#FFF9C4" },
        { name: "Подтвержден", color: "#B3E5FC" },
        { name: "Отложен на доставку", color: "#DCEDC8" },
        { name: "В транзите у ТК", color: "indigo" },
        { name: "Доставлен", color: "green" },
        { name: "Отложен на СДЭК", color: "yellow" },
        { name: "Отложен на Почту России", color: "orange" },
        { name: "Отложен на самовывоз", color: "pink" },
        { name: "Недозвон", color: "#CFD8DC" },
        { name: "Отменен", color: "#E8EAF6" },
        { name: "Архивный", color: "#E8EAF6" },
      ],
      payment_methods: [
        "Наличные",
        "Перевод на карту",
        "Безналичная оплата",
        "Смешанный",
      ],
      isDisabledSaveStatus: true,
      loading: "",
      isCommentDialogOpen: false,
    };
  },
  watch: {
    "order.order_status"() {
      //сравниваем новое и предыдущее значения
      //если отличаецца то кнопку Сохранить включаем
      if (this.old_one_order.order_status != this.order.order_status) {
        this.isDisabledSaveStatus = false;
      } else if (!this.isDisabledSaveStatus) {
        this.isDisabledSaveStatus = true;
      }
    },
  },
  methods: {
    //изменение чек бокса посылания емейла при закрытии заказа
    while_closing_sent_email_upd(new_val) {
      if (new_val) {
        this.sent_email_while_closing_label = "Email о закрытии заказа шлём";
      } else {
        this.sent_email_while_closing_label = "Email о закрытии заказа не шлём";
      }
    },
    //для вызова из дочернего компонента SelectBox через Emit для изменения в родительском
    paymentMethodSelectUpd(val) {
      this.order.payment_method = val.value;
    },
    //сохранение измененного статуса на архивный из диалога
    saveDialogStatus() {
      this.close_order_dialog = false;
      const { updateOrderStatus } = useOrderStore();
      updateOrderStatus(
        this.order,
        this.old_one_order.order_status,
        this.is_sent_email_while_closing
      );
    },
    Format(val) {
      val.setHours(12, 0, 0, 0);
      val = val.toLocaleDateString("ru-RU");
      return val;
    },
    onUpdateComments() {
      this.isCommentDialogOpen = false;
    },
    onUpdateComposList() {
      //вызываецца из дочернего OrderCompos - закрываем диалог
      this.compos = false;
    },
    onSaveOrder() {
      this.dialog = false;
    },
    SaveStatus() {
      //если заказ закрываецца
      if (this.order.order_status == "Доставлен" || this.order.order_status == "Архивный") {
        this.is_sent_email_while_closing = true
        this.isCancelOrder = false;
        this.close_order_dialog = true;
      } else if (this.order.order_status == "Отменен") {
        this.is_sent_email_while_closing = false
        this.isCancelOrder = true;
        this.close_order_dialog = true;
      } else {
        const { updateOrderStatus } = useOrderStore();
        updateOrderStatus(this.order, this.old_one_order.order_status);
      }
    },
    ColorHandler(new_status) {
      if (new_status) {
        const found = this.order_statuses.find(
          (item) => item.name == new_status
        );
        if (found?.color === undefined) {
          return "grey";
        } else {
          return found.color;
        }
      }
    },
  },
};
</script>
<style>
.label {
  text-align: left;
  padding: 2px 0 4px 17px;
  color: dimgrey;
  margin: -4px;
  font-size: 12px;
  opacity: 0.6;
}
</style>
