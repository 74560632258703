import { defineStore } from "pinia";
import axios from "axios";
import { useSnackBarStore } from "@/stores/snackbar.store";
import { useAuthStore } from '@/stores/auth.store'


export const useOrderStore = defineStore({
  id: "orders",
  state: () => ({
    orders: [],
    isLoading: false,
    pageTotal: 0,
    orderTotalCount: 0
  }),
  actions: {
    async setOrders(val) {
      this.orders = val
    },
    async pageCountIncrement() {
      this.pageCount++
    },
    async ordersReset() {
      this.orders = []
    },
    async pageCountReset() {
      this.pageCount = 0
    },
    async fetchOrderByPage(sub_action, page_number) {
      if (parseInt(page_number) > 0 && sub_action != '') {
        this.orders = [];
        this.isLoading = true;
        const authStore = useAuthStore()
        authStore.authHeader()
        this.orderTotalCount = 0;
        await axios
          .post(process.env.VUE_APP_API_ORDERS_URL, {
            page_number: page_number,
            action: "getOrderByPage",
            sub_action: sub_action
          })
          .then((response) => {
            if (response.data.result == "error!") {
              const snackBarStore = useSnackBarStore()
              snackBarStore.error(response.data.descr)
              if (response.data.descr.includes('Key')) {
                const auth = useAuthStore()
                auth.logout()
              }
            } else {
              if (Array.isArray(response.data.orders) && response.data.orders.length > 0) {
                this.orders = response.data.orders
                this.pageTotal = response.data.total_page
                this.orderTotalCount = response.data.total_count
              } else {
                const snackBarStore = useSnackBarStore()
                snackBarStore.error('Заказы не найдены')
              }
            }
            this.isLoading = false;
          })
          .catch((error) => {
            const snackBarStore = useSnackBarStore()
            snackBarStore.error(error)
            this.isLoading = false
          });
      } else {
        const snackBarStore = useSnackBarStore()
        snackBarStore.error('The wrong input data detected!')

      }
    },
    async fetchOrderById(order_id) {
      this.isLoading = true;
      const authStore = useAuthStore()
      authStore.authHeader()
      await axios
        .post(process.env.VUE_APP_API_ORDERS_URL, {
          order_id: order_id,
          action: "getOrderById",
        })
        .then((response) => {
          if (response.data.result == "error!") {
            const snackBarStore = useSnackBarStore()
            snackBarStore.error(response.data.descr)
            if (response.data.descr.includes('Key')) {
              const auth = useAuthStore()
              auth.logout()
            }
          } else {
            if (Array.isArray(response.data.orders) && response.data.orders.length > 0) {
              this.orders = this.orders.map(function (item) {
                if (item.id == order_id) {
                  item = response.data.orders[0]
                }
                return item
              });
            }
          }
          this.isLoading = false;
        })
        .catch((error) => {
          const snackBarStore = useSnackBarStore()
          snackBarStore.error(error)
          this.isLoading = false
        });
    },
    async updateComposOrder(new_compos, order_id, total_summ) {
      this.isLoading = true
      const snackBarStore = useSnackBarStore()
      const authStore = useAuthStore()
      authStore.authHeader()
      await axios
        .post(process.env.VUE_APP_API_ORDERS_URL, {
          action: "updateComposOrder",
          new_compos: new_compos,
          order_id: order_id,
          total_summ: total_summ
        })
        .then((response) => {
          if (response.data.descr) {
            if (response.data.result != 'error!') {
              snackBarStore.success(response.data.descr)
              this.fetchOrderById(order_id)
            } else {
              snackBarStore.error(response.data.descr)
              if (response.data.descr.includes('Key')) {
                const auth = useAuthStore()
                auth.logout()
              }
            }
          } else {
            snackBarStore.error("Ahtung! Error occured!")
          }
          //console.log(response.data);
          this.isLoading = false;
        })
        .catch((error) => {
          snackBarStore.error(error)
          this.isLoading = false
        });
    },
    async updateOrderStatus(order, old_status, is_sent_email_while_closing) {
      this.isLoading = true;
      const snackBarStore = useSnackBarStore()
      const authStore = useAuthStore()
      authStore.authHeader()
      await axios
        .post(process.env.VUE_APP_API_ORDERS_URL, {
          action: "updateOrderStatus",
          order: order,
          old_status: old_status,
          is_sent_email_while_closing: is_sent_email_while_closing
        })
        .then((response) => {
          if (response.data.descr) {
            if (response.data.result != 'error!') {
              snackBarStore.success(response.data.descr)
              this.fetchOrderById(order.id)
            } else {
              snackBarStore.error(response.data.descr)
              if (response.data.descr.includes('Key')) {
                const auth = useAuthStore()
                auth.logout()
              }
            }
          } else {
            snackBarStore.error("Ahtung! Error occured!")
          }
          this.isLoading = false;
        })
        .catch((error) => {
          snackBarStore.error(error)
          this.isLoading = false
        });
    },
    async addOrderComment(new_comment, order_id) {
      this.isLoading = true;
      const snackBarStore = useSnackBarStore()
      const authStore = useAuthStore()
      authStore.authHeader()
      await axios
        .post(process.env.VUE_APP_API_ORDERS_URL, {
          action: "addOrderComment",
          new_comment: new_comment,
          order_id: order_id
        })
        .then((response) => {
          if (response.data.descr) {
            if (response.data.result != 'error!') {
              snackBarStore.success(response.data.descr)
              this.fetchOrderById(order_id)
            } else {
              snackBarStore.error(response.data.descr)
              if (response.data.descr.includes('Key')) {
                const auth = useAuthStore()
                auth.logout()
              }
            }
          } else {
            snackBarStore.error("Ahtung! Error has occured!")
          }
          this.isLoading = false;
        })
        .catch((error) => {
          snackBarStore.error(error)
          this.isLoading = false
        });
    },
    async updateOrder(order) {
      const authStore = useAuthStore()
      authStore.authHeader()
      await axios
        .post(process.env.VUE_APP_API_ORDERS_URL, {
          action: 'orderUpdate',
          order: order,
        })
        .then((response) => {
          if (response.data.result == "error!") {
            const snackBarStore = useSnackBarStore()
            snackBarStore.error(response.data.descr)
            if (response.data.descr.includes('Key')) {
              const auth = useAuthStore()
              auth.logout()
            }
          } else {
            const snackBarStore = useSnackBarStore()
            snackBarStore.success(response.data.descr)
          }
        })
        .catch((error) => {
          const snackBarStore = useSnackBarStore()
          snackBarStore.error(error)
        });
    },
    async searchOrderByAllFields(fields) {
      this.orders = [];
      this.isLoading = true;
      const authStore = useAuthStore()
      authStore.authHeader()
      await axios
        .post(process.env.VUE_APP_API_ORDERS_URL, {
          action: "SearchOrderByAllFields",
          fields: fields,
        })
        .then((response) => {
          if (response.data.result == "error!") {
            const snackBarStore = useSnackBarStore()
            snackBarStore.error(response.data.descr)
            if (response.data.descr.includes('Key')) {
              const auth = useAuthStore()
              auth.logout()
            }
          } else {
            if (Array.isArray(response.data.orders) && response.data.orders.length > 0) {
              this.orders = response.data.orders
            } else {
              const snackBarStore = useSnackBarStore()
              snackBarStore.error('Заказы не найдены')
            }
          }
          this.isLoading = false;
        })
        .catch((error) => {
          const snackBarStore = useSnackBarStore()
          snackBarStore.error(error)
          this.isLoading = false
        });

    },
    async searchOnlyOpenedOrder() {
      this.orders = [];
      this.isLoading = true;
      const authStore = useAuthStore()
      authStore.authHeader()
      await axios
        .post(process.env.VUE_APP_API_ORDERS_URL, {
          action: "searchOnlyOpenedOrder",
        })
        .then((response) => {
          if (response.data.result == "error!") {
            const snackBarStore = useSnackBarStore()
            snackBarStore.error(response.data.descr)
            if (response.data.descr.includes('Key')) {
              const auth = useAuthStore()
              auth.logout()
            }
          } else {
            if (Array.isArray(response.data.orders) && response.data.orders.length > 0) {
              this.orders = response.data.orders
            } else {
              const snackBarStore = useSnackBarStore()
              snackBarStore.error('Заказы не найдены')
            }
          }
          this.isLoading = false;
        })
        .catch((error) => {
          const snackBarStore = useSnackBarStore()
          snackBarStore.error(error)
          this.isLoading = false
        });

    }
  },
});
