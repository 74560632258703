import { defineStore } from "pinia";
import { fetchWrapper } from '@/helpers/fetch-wrapper'


export const useSearchStore = defineStore({
    id: "search_store",
    state: () => ({
        orders: [],
        isLoading: false,
    }),
    actions: {
        async searchOrderById(query) {
            //console.log(`GetProductById(${item_id})`)
            this.isLoading = true;
            if (query > 0) {
                const response = await fetchWrapper.post(process.env.VUE_APP_API_ORDERS_URL, {
                    action: 'searchOrderById',
                    query: query
                });
                if (Array.isArray(response) && response.length > 0) {
                    this.orders = response
                } else {
                    this.orders = []
                }
                this.isLoading = false;
            }
        },
    }
})
