<template>
    <div class="dropdown">
        <input class="dropdown-input" @blur="exit()" @keyup="keyMonitor" v-model="searchFilter" :modelValue="searchFilter"
            :placeholder="props.placeholder" />
        <div class="dropdown-content" ref="target" v-show="optionsShown" :id="props.dropdown_id">
            <div class="dropdown-item" @mousedown="selectOption(option)" v-for="(option, index) in filteredOptions"
                :key="index">
                <v-row :class="option.class" align="center" justify="start">
                    <v-col cols="2">
                        <img :width="60" :src="option.img_link">
                    </v-col>
                    <v-col cols="6" justify="center" class="mx-1">
                        <p class="mb-1">{{ option.title }}</p>
                        <p class="text-caption">{{ option.vendor_code }}</p>
                    </v-col>
                    <v-col cols="2" class="mx-auto">
                        {{ option.price }}р.
                    </v-col>
                </v-row>
            </div>
        </div>
    </div>
</template>

<script setup>
/* eslint-disable */
import { useMouseInElement } from '@vueuse/core'
import { reactive, ref, watch, computed } from 'vue'

const props = defineProps({
    options: {
        type: Array,
        required: true,
        note: 'Options of dropdown. An array of options with id and name',
    },
    dropdown_id: {
        type: String,
        required: false,
        note: 'id of dropdown item for styling etc',
    },
    placeholder: {
        type: String,
        required: false,
        note: 'placeholder for input',
    }
})

const selected = ref({})
const optionsShown = ref(false)
const searchFilter = ref('')
const target = ref(null)
const mouse = reactive(useMouseInElement(target))
const activeElement = ref(-1)

const emit = defineEmits(['selected', 'filter'])

const filteredOptions = computed(() => {
    if (searchFilter.value && props.options.length > 0) {
        const filtered = [];
        const regOption = new RegExp(searchFilter.value, 'ig');
        for (const option of props.options) {
            if (searchFilter.value.length < 1 || option.vendor_code.match(regOption)) {
                filtered.push(option);
            }
        }
        activeElement.value = -1
        filtered.length > 0 ? optionsShown.value = true : optionsShown.value = false
        return filtered
    } else {
        return null
    }
})

const selectOption = (option) => {
    selected.value = option;
    optionsShown.value = false;
    searchFilter.value = selected.value.name;
    emit('selected', selected.value);
}

const exit = () => {
    if (mouse.isOutside) {
        selected.value = {};
        searchFilter.value = '';
        emit('selected', '')
    } else if (selected.value?.name) {
        searchFilter.value = selected.value.name
        emit('selected', selected.value)
        optionsShown.value = false
    }
}

const keyMonitor = (event) => {
    if (event.key === "Enter" && filteredOptions.value?.length > 0) {
        for (let i = 0; i < filteredOptions.value.length; i++) {
            if (filteredOptions.value[i].class == 'active') {
                selectOption(filteredOptions.value[i]);
                break
            }
        }
    } else if (event.key === "ArrowDown") {
        if (filteredOptions.value?.length > 0) {
            if (activeElement.value < filteredOptions.value.length - 1) {
                activeElement.value++
            } else {
                activeElement.value = 0
            }
            filteredOptions.value.forEach(element => {
                element.class = ''
            })
            filteredOptions.value[activeElement.value].class = 'active'
        }

    } else if (event.key === "ArrowUp") {
        if (filteredOptions.value?.length > 0) {
            if (activeElement.value > 0) {
                activeElement.value--
            } else {
                activeElement.value = filteredOptions.value.length - 1
            }
            filteredOptions.value.forEach(element => {
                element.class = ''
            })
            filteredOptions.value[activeElement.value].class = 'active'
        }
    }
}

watch(searchFilter, (newFilter) => {

    if (filteredOptions) {
        if (filteredOptions.length === 0) {
            selected.value = {};
        } else {
            selected.value = filteredOptions[0];
        }
        emit('filter', newFilter);
    }
})


</script>


<style lang="scss" scoped>
.active {
    background-color: #e7ecf5;
}

input :active,
:hover,
:focus {
    outline: 0;
    outline-offset: 0;
}

input::placeholder {
    color: grey;
    font-size: 1.2em;
}


.dropdown {
    position: relative;
    display: block;
    color: rgb(44, 62, 80);


    .dropdown-input {
        background: #f5f5f5;
        cursor: pointer;
        border-bottom: 1px solid #aca9a9;
        border-radius: 3px;
        color: #333;
        display: block;
        font-size: 14px;
        padding: 6px 16px;
        height: 40px;
        min-width: 250px;
        width: 100%;

        &:hover {
            background: #f5f5f5;
        }
    }

    .dropdown-content {
        position: absolute;
        background-color: #fff;
        min-width: 270px;
        box-shadow: 1px 3px 3px grey;
        //box-shadow: 0px -8px 34px 0px rgba(0, 0, 0, 0.05);
        overflow: auto;
        z-index: 100;

        .dropdown-item {
            color: rgb(83, 80, 80);
            font-size: 14px;
            line-height: 1em;
            padding: 4px;
            text-decoration: none;
            display: block;
            cursor: pointer;
            overflow: hidden;

            &:hover {
                background-color: #dde2eb;
            }
        }
    }

    .dropdown:hover .dropdowncontent {
        display: block;
    }
}
</style>