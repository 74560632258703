import { defineStore } from 'pinia';
import { useSnackBarStore } from './snackbar.store';
import axios from 'axios';
import router from '@/router'

export const useAuthStore = defineStore({
    id: 'auth',
    state: () => ({
        // initialize state from local storage to enable user to stay logged in
        user: JSON.parse(localStorage.getItem('user')),
        isLoading: false,
        returnUrl: null
    }),
    actions: {
        async login(username, password) {
            this.isLoading = true;
            await axios
                .post(process.env.VUE_APP_API_AUTH_URL, {
                    username: username,
                    password: password
                })
                .then((response) => {
                    if (response.data.isSuccess) {
                        this.user = response.data.userProfile;
                        localStorage.setItem(
                            'user',
                            JSON.stringify(this.user),
                        );
                        // redirect to previous url or default to home page
                        router.push(this.returnUrl || '/');
                    } else {
                        const snackBarStore = useSnackBarStore()
                        snackBarStore.error(response.data.message)
                    }
                })
                .catch((error) => {
                    const snackBarStore = useSnackBarStore()
                    snackBarStore.error(error)
                })
                .finally(() => {
                    this.isLoading = false
                });
        },
        logout() {
            this.user = null;
            localStorage.removeItem('user');
            router.push('/login');
        },
        async authHeader() {
            await axios.interceptors.request.use(
                (config) => {
                    if (this.user && this.user.token) {
                        config.headers['Authorization'] = `Bearer ${this.user.token}`
                    }
                    return config;
                },
                (error) => {
                    return Promise.reject(error);
                }
            );
        }

    }
});
