import { createRouter, createWebHistory } from "vue-router";
import { useAuthStore } from "@/stores/auth.store";
import { LoginView } from "@/views/";

function checkOperatorPerm() {
  const auth = useAuthStore();
  if (!auth.user) {
    return "/login";
  } else if (parseInt(auth.user.role) > 2) {
    return "/forbidden";
  }
}

function checkSellerPerm() {
  const auth = useAuthStore();
  if (!auth.user) {
    return "/login";
  } else if (parseInt(auth.user.role) > 3) {
    return "/forbidden";
  }
}

const routes = [
  {
    path: "/",
    redirect: "/orders/all/page/1",
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
  {
    path: "/orders/page/:number(\\d+)",
    component: () => import("@/views/OrderPageView.vue"),
    props: true,
  },
  {
    path: "/orders/:action/page/:number(\\d+)",
    component: () => import("@/views/OrderPageView.vue"),
    props: true,
    beforeEnter: [checkOperatorPerm],
  },
  {
    path: "/orders/all/",
    redirect: "/orders/all/page/1",
  },
  {
    path: "/orders/transit/",
    redirect: "/orders/transit/page/1",
  },
  {
    path: "/orders/archive/",
    redirect: "/orders/archive/page/1",
  },
  {
    path: "/create_order",
    component: () => import("@/views/CreateOrderView.vue"),
    beforeEnter: [checkSellerPerm],
  },
  {
    path: "/search",
    component: () => import("@/views/SearchOrderView.vue"),
    beforeEnter: [checkOperatorPerm],
  },
  {
    path: "/qrcode",
    component: () => import("@/views/QrCodeView.vue"),
    beforeEnter: [checkOperatorPerm],
  },
  {
    //page 404
    path: "/:pathMatch(.*)*",
    name: "notfound",
    component: () => import("@/views/NotFound.vue"),
  },
  {
    //forbidden
    path: "/forbidden",
    name: "forbidden",
    component: () => import("@/views/ForbiddenView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  //при возвращении на страницу сохраняет позицию прокрутки
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(savedPosition)
      }, 500)
    })
  },
});

export default router;
