<template>
    <v-sheet class="bg-deep-grey" rounded>
        <v-card class="mx-auto mt-14 pa-8" max-width="400">
            <v-form v-model="form" @submit.prevent="onSubmit">
                <v-text-field v-model="username" variant="underlined" :readonly="loading" :rules="[required]"
                    class="mb-2" label="Имя"></v-text-field>
                <v-text-field v-model="password" :readonly="loading" variant="underlined"
                    :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'" :rules="[required]"
                    :type="show_pass ? 'text' : 'password'" name="input-10-2" label="Пароль"
                    class="input-group--focused" @click:append="show_pass = !show_pass"></v-text-field>

                <br />
                <v-btn :disabled="!form" :loading="loading" block color="success" size="large" type="submit"
                    variant="elevated">
                    Вход </v-btn>
            </v-form>
        </v-card>
    </v-sheet>
    <SnackBar />
</template>
<script>
import { storeToRefs } from "pinia"
import { useAuthStore } from '@/stores/auth.store'
import SnackBar from "@/components/SnackBar.vue"
import sha256 from '@/services/sha512'

export default {
    components: {
        SnackBar
    },
    data: () => ({
        form: false,
        username: null,
        password: null,
        loading: false,
        show_pass: false,
    }),
    mounted() {
        const { isLoading } = storeToRefs(useAuthStore())
        this.loading = isLoading
    },
    methods: {
        onSubmit() {
            if (!this.form) return
            const hash_pass = this.GetHash(this.password)
            const authStore = useAuthStore()
            authStore.login(this.username, hash_pass)
        },
        required(v) {
            return !!v || 'Field is required'
        },
        GetHash(inputStr) {
            const shaObj = new sha256("SHA-512", "TEXT", { encoding: "UTF8" });
            shaObj.update(inputStr);
            return shaObj.getHash("HEX");
        }

    },
}
</script>