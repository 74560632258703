<template>
    <v-container v-if="auth.user" class="nav-wrap">
        <v-app-bar color="deep-purple">
            <span class="d-flex d-sm-none">
                <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
            </span>
            <div v-if="isShowInput" class="search" :style="searchFieldWidth">
                <v-autocomplete v-model="model" :items="orders" color="primary" item-title="id" item-value="title"
                    v-model:search="search" :loading="isLoading" hide-details hide-no-data clearable
                    @click:clear="$nextTick(() => { model = null, orders = [] })">
                    <template v-slot:item="{ props, item }">
                        <v-list-item v-bind="props" :prepend-avatar="item.raw.img_link"
                            :title="item.raw.id + ' ' + item.raw.city + ' ' + item.raw.date"
                            :subtitle="'Статус: ' + item.raw.order_status + ', Сумма: ' + item.raw.total_summ + ' р'">
                        </v-list-item>
                    </template>
                </v-autocomplete>
            </div>
            <div v-else class="pl-2 d-none d-sm-flex">
                <div v-for="(menu_item, index) in menu_items" :key=index>
                    <v-btn :to=menu_item.link>{{ menu_item.name }}</v-btn>
                </div>
            </div>
            <v-spacer></v-spacer>
            <div>
                <v-icon @click="isShowInput = !isShowInput" class="mr-4">mdi-magnify</v-icon>
            </div>
            <v-btn icon="mdi-logout" class="logout-btn" @click="auth.logout()"></v-btn>
        </v-app-bar>
        <v-navigation-drawer v-model="drawer">
            <v-list nav dense>
                <v-list-item v-for="(menu_item, index) in menu_items" :key=index>
                    <v-btn variant="flat" :to=menu_item.link>{{ menu_item.name }}</v-btn>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
        <SnackBar />
    </v-container>
</template>
<script>
import { storeToRefs } from "pinia"
import { useAuthStore } from '@/stores/auth.store'
import { useSearchStore } from '@/stores/search.store'
import { useOrderStore } from "@/stores/orders.store"
import SnackBar from "@/components/SnackBar.vue";
//import { useRoute } from "vue-router";

export default {
    name: "nav-bar",
    components: {
        SnackBar
    },
    data() {
        return {
            drawer: false,
            orders: [],
            isShowInput: false,
            menu_items_all: [
                { name: 'Заказы', link: '/orders/all', perm_role: 3 },
                { name: 'Транзит', link: '/orders/transit', perm_role: 3 },
                { name: 'Архив', link: '/orders/archive', perm_role: 3 },
                { name: 'Создать заказ', link: '/create_order', perm_role: 4 },
                { name: 'Поиск', link: '/search', perm_role: 3 },
                { name: 'QR', link: '/qrcode', perm_role: 3 },
            ],
            menu_items: [],
            auth: useAuthStore(),
            isLoading: false,
            search: null,
            model: null,
        }
    },
    mounted() {
        const role = this.auth.user?.role
        if (role > 0) {
            //отбираем только те где роль позволяет
            this.menu_items = this.menu_items_all.filter(item => role < item.perm_role)
        } else {
            this.auth.logout()
        }
    },
    watch: {
        search(query) {
            if (this.isLoading) return
            if (query.length < 1 || query.length > 7) return
            const searchStore = useSearchStore()
            const { orders, isLoading } = storeToRefs(searchStore)
            this.orders = orders
            this.isLoading = isLoading
            searchStore.searchOrderById(query);
        },
        model(val) {
            if (val > 0) {
                const new_order = this.orders.filter(item => item.id == val)
                const { setOrders } = useOrderStore()
                setOrders(new_order)
                //обнуляем поисковые данные и закрываем поисковый инпут
                this.isShowInput = false
                this.model = null
                this.orders = []

            }
        },
    },
    computed: {
        searchFieldWidth() {
            let width = 0;
            switch (this.$vuetify.display.name) {
                case 'xs':
                    width = 100
                    break
                case 'sm':
                    width = 90
                    break
                case 'md':
                    width = 70
                    break
                case 'lg':
                    width = 50
                    break
                case 'xl':
                    width = 30
                    break
            }
            const ret = `width: ${width}%`
            return ret
        }
    }
}
</script>

<style scoped>
.nav-wrap {
    height: 90px;
}

.logout-btn {
    float: inline-end
}

.search {
    margin: 0 10%;
}
</style>