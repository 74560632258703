import { useAuthStore } from '@/stores/auth.store'
import { useSnackBarStore } from '@/stores/snackbar.store';

export const fetchWrapper = {
    get: request('GET'),
    post: request('POST'),
    put: request('PUT'),
    delete: request('DELETE')
};

function request(method) {
    return (url, body) => {
        const requestOptions = {
            method,
            headers: authHeader(url)
        };
        if (body) {
            requestOptions.headers['Content-Type'] = 'application/json';
            requestOptions.body = JSON.stringify(body);
        }
        return fetch(url, requestOptions).then(handleResponse);
    }
}


function authHeader() {
    const authStore = useAuthStore()
    const { user } = authStore
    if (user && user.token) {
        return { Authorization: `Bearer ${user.token}` };
    }
    else {
        const auth = useAuthStore()
        auth.logout()
    }
}

function handleResponse(response) {
    return response.text()
        .then(text => {
            const data = text && JSON.parse(text);
            if (data.result == "error!") {
                const snackBarStore = useSnackBarStore()
                snackBarStore.error(data.descr)
                if (data.descr.includes('Key')) {
                    const auth = useAuthStore()
                    auth.logout()
                }
                //return Promise.reject(data.descr);
            } else if (data.result == "ok") {
                const snackBarStore = useSnackBarStore()
                snackBarStore.success(data.descr)
                return 'ok'
            } else {
                return data
            }
        })
        .catch(error => {
            const snackBarStore = useSnackBarStore()
            snackBarStore.error(error)
        })
}    
