<template>
    <v-container>
        <div class="wrapper">
            <h4 class="text--secondary mb-5">Создание заказа</h4>
            <v-row>
                <v-col cols="12" sm="6">
                    <v-text-field color="primary" density="compact" :value="order.client" v-model="order.client"
                        label="Имя">
                    </v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-text-field color="primary" density="compact" :value="order.last_name" v-model="order.last_name"
                        label="Фамилия">
                    </v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-text-field color="primary" density="compact" :value="order.phone" v-model="order.phone"
                        label="Телефон">
                    </v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-text-field color="primary" density="compact" type="email" v-model="order.email" :value="order.email"
                        label="e-mail">
                    </v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                    <SelectBox label="Способ оплаты" id="" :modelValue="order.payment_method" :options="payment_methods"
                        @update:modelValue="PaymentMethodSelectUpd" />
                </v-col>
                <v-col cols="12" sm="6">
                    <v-text-field color="primary" disabled density="compact" type="number" :value="total_summ"
                        v-model="total_summ" label="Стоимость">
                    </v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-checkbox color="primary" v-model="order.isReceiptReq" label="Чек?"></v-checkbox>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-text-field color="primary" density="compact" type="number" v-model="order.discount" label="Скидка">
                    </v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-textarea auto-grow rows="1" clearable clear-icon="mdi-close-circle"
                        placeholder="Комментарий к заказу" v-model="new_comment">
                        counter>
                    </v-textarea>
                </v-col>
            </v-row>
            <v-row align="center" justify="start">
                <v-col cols="12" sm="6">
                    <AutoComplete :options="options" dropdown_id="dropdown-item" placeholder="Артикул"
                        v-on:selected="onSelect" v-on:filter="getDropdownValues">
                    </AutoComplete>
                </v-col>
            </v-row>
            <div v-for="(val, index) in compos_list" :key="val">
                <v-sheet v-if="!val.isDeleted" class="pa-4">
                    <v-card-text class="text-h6 text-md-h5 mb-4">
                        {{ compos_list.length > 1 ? index + 1 + '. ' : '' }}
                        {{ val.title }}
                    </v-card-text>
                    <v-row justify="start">
                        <v-col cols="12" sm="6" md="2"><a :href="val.full_link">
                                <v-img height="200" width="200" :src="val.img_link"></v-img>
                            </a>
                        </v-col>
                        <v-col cols="12" sm="6" md="2">
                            <div v-html="val.short_descr + '<br />Артикул: ' + val.vendor_code"></div>
                        </v-col>
                        <v-col cols="12" sm="6" md="2">
                            <SelectBox label="Размер" :id="index" :elemStyle="Colorer(val)" :modelValue="val.selected_size"
                                :options="val.sizes" @update:modelValue="SizesSelectUpd" />
                        </v-col>
                        <v-col cols="12" sm="6" md="2">
                            <v-text-field color="primary" disabled density="compact" type="number" :value="val.price"
                                v-model="val.price" label="Цена">
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="2">
                            <v-text-field color="primary" density="compact" type="number" :value="val.vol" v-model="val.vol"
                                label="Количество" min="1" max="100">
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="1">
                            <v-btn text @click="DeleteProduct(val.id, val.selected_size)" color="danger">Удалить</v-btn>
                        </v-col>
                    </v-row>
                </v-sheet>
            </div>
            <v-row justify="end" class="ma-0">
                <v-col cols="12" md="3" align-self="center">
                    <v-btn :loading="loading" :disabled="!isValidAllFields" class="ma-4" large elevation="4" rounded="lg"
                        color="success" @click="Save">
                        Сохранить
                    </v-btn>
                </v-col>
            </v-row>
        </div>
    </v-container>
</template>


<script>
import { fetchWrapper } from "@/helpers/fetch-wrapper"
import AutoComplete from '@/components/AutoComplete.vue'
import SelectBox from "@/components/SelectBox.vue"

export default {
    name: "create-order",
    components: { AutoComplete, SelectBox },
    data() {
        return {
            loading: false,
            order: {
                client: "",
                last_name: "",
                phone: "",
                email: "",
                total_summ: 0,
                isReceiptReq: false,
                discount: '',
            },
            items: [],
            compos_list: [],
            payment_methods: ['Наличные', 'Перевод на карту', 'Безналичная оплата', 'Смешанный'],
            new_comment: "",
            options: [],
            selected: {}
        }
    },
    methods: {
        //для вызова из дочернего компонента SelectBox через Emit для изменения в родительском
        PaymentMethodSelectUpd(val) {
            this.order.payment_method = val.value
        },
        //для вызова из дочернего компонента SelectBox через Emit для изменения в родительском
        SizesSelectUpd(val) {
            if (val.props.id < this.compos_list.length) {
                this.compos_list[val.props.id].selected_size = val.value
            }
        },
        async onSelect(val) {
            await this.GetProductById(val.id)
        },
        async getDropdownValues(query) {
            if (query?.length > 0 && query?.length < 10) {
                this.options = await fetchWrapper.post(process.env.VUE_APP_API_ORDERS_URL, {
                    action: 'fastSearchProduct',
                    query: query
                });
            }
        },
        async DeleteProduct(delete_id, selected_size) {
            //  console.log(`id: ${delete_id}, size: ${selected_size}`)

            if (this.compos_list.length === 1) {
                this.compos_list = []
            } else {
                for (let i = 0; i < this.compos_list.length; i++) {
                    if (this.compos_list[i].id == delete_id && this.compos_list[i].selected_size == selected_size) {
                        this.compos_list.splice(i, 1)
                    }
                }

            }
        },
        async Save() {
            this.order.total_summ = this.total_summ
            this.loading = true
            const result = await fetchWrapper.post(process.env.VUE_APP_API_ORDERS_URL, {
                action: 'createOrderBySeller',
                order: this.order,
                total_order: this.compos_list,
                comment: this.new_comment
            });
            if (result == 'ok') {
                this.total_summ = 0
                this.order = {}
                this.compos_list = []
                this.items = []
                this.new_comment = ''
            }
            this.loading = false
        },
        async GetProductById(item_id) {
            item_id = parseInt(item_id);
            if (item_id > 0) {
                this.loading = true
                const product = await fetchWrapper.post(process.env.VUE_APP_API_ORDERS_URL, {
                    action: 'getProductById',
                    query: item_id
                });
                if (product?.title) {
                    // console.log(product)
                    this.compos_list.push(product)
                    this.items = []
                }
                this.loading = false

            }
        },
        Colorer(val) {
            if (val.selected_size === undefined || val.selected_size === '') {
                val.isValid = false;
                return 'border-color: red'
            } else {
                val.isValid = true;
                return 'border-color: green'
            }
        },
    },
    computed: {
        isValidAllFields() {
            if (this.compos_list.length > 0) {
                let res = true;
                this.compos_list.forEach(element => {
                    if (!element.isValid) {
                        res = false
                    }
                });
                return res
            } else {
                return false
            }

        },
        total_summ() {
            let total = 0;
            this.compos_list.forEach(item => {
                //   if (item.isDeleted !== true)
                total += (item.price * item.vol)
            })
            return total
        },
    }

}


</script>

<style scoped>
.wrapper {
    min-height: 1200px;
}
</style>