<template>
    <v-container>
        <v-card v-if="!loading">
            <v-card-title class="text--secondary">Поиск заказа</v-card-title>
            <v-card-text class="ma-1 pa-1">
                <v-row>
                    <v-col cols="12" sm="6">
                        <v-text-field color="primary" density="compact" :value="order.client" v-model="order.client"
                            label="Имя" @update:modelValue="CheckValid">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-text-field color="primary" density="compact" :value="order.last_name" v-model="order.last_name"
                            label="Фамилия" @update:modelValue="CheckValid">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-text-field color="primary" density="compact" :value="order.phone" v-model="order.phone"
                            label="Телефон" @update:modelValue="CheckValid">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-text-field color="primary" density="compact" type="email" v-model="order.email"
                            :value="order.email" label="e-mail" @update:modelValue="CheckValid">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-text-field color="primary" density="compact" :value="order.city" v-model="order.city"
                            label="Город" @update:modelValue="CheckValid">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-text-field color="primary" density="compact" :value="order.track_number"
                            v-model="order.track_number" label="Трек номер" @update:modelValue="CheckValid">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-text-field color="primary" density="compact" :value="order.vendor_code"
                            v-model="order.vendor_code" label="Артикул" @update:modelValue="CheckValid">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-select variant="solo" density="compact" v-model="order.deliv_type" :items="deliv_types"
                            item-title="name" item-value="name" label="Доставка" @update:modelValue="CheckValid">
                        </v-select>
                    </v-col>
                    <v-col cols="12" md="3" justify="end" class="ml-auto">
                        <v-btn :disabled="!isValidAllFields" large elevation="4" rounded="lg" color="success"
                            @click="OrderSearch">
                            Искать
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
            <div class="text-subtitle-1 text-left ml-4">Фильтры</div>
            <v-card-actions>
                <v-btn large rounded="lg" variant="tonal" elevation="1" color="orange" @click="searchOnlyOpenedOrder">
                    Незакрытые
                </v-btn>
            </v-card-actions>
        </v-card>
        <v-card v-else elevation="0">
            <v-progress-circular :size="70" :width="7" color="deep-purple" indeterminate></v-progress-circular>
        </v-card>
    </v-container>
    <v-container>
        <v-row v-if="Array.isArray(orders) && orders.length">
            <v-col cols="12">
                <div class="text-h5 mb-6 text-deep-purple">{{ found_noun_declination }} {{ orders.length }}
                    {{ found_orders_noun_declination }}
                </div>
            </v-col>
            <v-col v-for="card in orders" :key="card" cols="12" md="6">
                <OrderCard :oneOrder="card" />
            </v-col>
        </v-row>
    </v-container>
</template>


<script>
import { useOrderStore } from "@/stores/orders.store"
import OrderCard from "@/components/OrderCard.vue";
import { storeToRefs } from "pinia"
import nounDeclination from "@/helpers/nounDeclination"

export default {
    name: "search-order",
    components: {
        OrderCard,
    },
    data() {
        return {
            loading: false,
            orders: [],
            order: {
                client: "",
                last_name: "",
                phone: "",
                email: "",
                city: "",
                track_number: "",
                vendor_code: "",
                deliv_type: ""
            },
            items: [],
            compos_list: [],
            model: null,
            search: null,
            isValidAllFields: false,
            found_orders_noun_declination: '',
            found_noun_declination: '',
            deliv_types: [
                { name: 'Курьером по Москве' },
                { name: 'Самовывоз' },
                { name: 'СДЭК до ПВЗ' },
                { name: 'СДЭК до двери' },
                { name: 'Почта России' },
                { name: 'Шоурум' },
            ]
        }
    },
    mounted() {
        const { orders, isLoading } = storeToRefs(useOrderStore())
        const orderStore = useOrderStore()
        orderStore.ordersReset()
        this.loading = isLoading
        this.orders = orders
    },
    methods: {
        //проверяем каждое поле на наличие введенных данных
        //если хоть в одном поле есть больше одного символов то кнопку подсвечиваем
        CheckValid() {
            let isFound = false;
            Object.keys(this.order).forEach(key => {
                if (this.order[key].length > 1) {
                    this.isValidAllFields = true
                    isFound = true;
                    return
                }
            });
            if (!isFound && this.isValidAllFields) {
                this.isValidAllFields = false
            }


        },
        //для вызова из дочернего компонента SelectBox через Emit для изменения в родительском
        delivTypesSelectUpd(val) {
            this.order.deliv_type = val.value
            this.CheckValid()
        },
        async OrderSearch() {
            const { searchOrderByAllFields } = useOrderStore()
            await searchOrderByAllFields(this.order)
        },
        async searchOnlyOpenedOrder() {
            const orderStore = useOrderStore()
            orderStore.ordersReset()
            const { searchOnlyOpenedOrder } = useOrderStore()
            await searchOnlyOpenedOrder()
        },

    },
    watch: {
        orders() {
            this.found_orders_noun_declination = nounDeclination(this.orders.length, 'заказ', 'заказа', 'заказов')
            this.found_noun_declination = nounDeclination(this.orders.length, 'Найден', 'Найдено', 'Найдено')
        }

    },
}


</script>