<template>
    <div class="select" :style="props.elemStyle">
        <div class="label">{{ label }}</div>
        <select v-model="model">
            <option v-for="(option, key) in props.options" :value="option" :key="key">
                {{ option }}
            </option>
        </select>
    </div>
</template>

<script setup>
/* eslint-disable */
import { computed } from "vue";

const props = defineProps({
    id: Number,
    label: String,
    options: Object,
    modelValue: String,
    elemStyle: String,
})

const emit = defineEmits(['update:modelValue'])

const model = computed({
    get: () => props.modelValue,
    set: (value) => {
        emit('update:modelValue', { props, value })
    }
})

</script>
<style scoped>
.select {
    border: 2px solid #eee;
    border-radius: 2px;
    user-select: none;
}

select:focus {
    outline: none;
}

.select select {
    width: 100%;
    padding: 3px 0 0 13px;
    cursor: pointer;
}


.label {
    text-align: left;
    padding: 2px 0 0 17px;
    color: dimgrey;
    margin: -4px;
    font-size: 12px;
    font-weight: 400;
    opacity: 0.6;
}

select {
    font: inherit;
    letter-spacing: inherit;
    word-spacing: inherit;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
}

select:not([multiple]) {
    padding-right: 1.2em;
    background-repeat: no-repeat;
    background-position: calc(100% - 0.25em) 0;
    background-size: 1em auto;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon icon-prefix-chevron-down' id='icon-prefix-chevron-down' viewBox='0 0 24 24'%3E%3Cpolyline points='6 9 12 15 18 9' fill='none' stroke-linecap='round' stroke-width='4' stroke='black' %3E%3C/polyline%3E%3C/svg%3E%0A");
}

/* скроет иконку стрелки в IE */
select::-ms-expand {
    display: none;
}
</style>