<template>
    <v-card class="order-comment">
        <v-toolbar color="primary">
            <v-card-text class="text-h6 text-lg-h5 pl-4">
                Комментарии к заказу № {{ OrderId }}
            </v-card-text>
            <v-spacer></v-spacer>
            <v-icon class="mr-4" @click="CloseDialog()">mdi-close</v-icon>
        </v-toolbar>
        <div class="add-comment-wrap">
            <v-textarea auto-grow variant="outlined" color="blue" rows="1" row-height="15" clearable
                clear-icon="mdi-close-circle" placeholder="Введите комментарий" class="pa-md-8 pa-2"
                v-model="new_comment" :rules="[(new_comment || '').length >= 4 || `Минимум 4 символа`]"> counter>
                <template v-slot:append>
                    <v-btn @click="SaveComment" :disabled="new_comment.length < 4" :loading="loading"
                        class="save-button" icon="mdi-content-save" id="save-btn">
                    </v-btn>
                </template>
            </v-textarea>
        </div>
        <div v-show="Comments.length > 0">
            <v-sheet v-for="comment in comments" :key="comment.id">
                <v-row no-gutters class="my-1 row-comments">
                    <v-col cols="4" sm="1">
                        <div class="text-md-text-body-2 text-subtitle-2 text-center">{{ comment.id }}</div>
                    </v-col>
                    <v-col cols="8" sm="3">
                        <div class="text-md-text-body-2 text-subtitle-2 text-center">{{ comment.date }}</div>
                    </v-col>
                    <v-col cols="12" sm="8" class="pl-4">
                        <div class="text-subtitle-1">{{ comment.text }}</div>
                    </v-col>
                </v-row>
            </v-sheet>
        </div>
        <v-card-action>
            <v-spacer></v-spacer>
            <v-btn class="ma-4" large elevation="4" rounded="lg" color="primary" @click="CloseDialog()">
                Отмена
            </v-btn>
        </v-card-action>
    </v-card>

</template>


<script>
import { useOrderStore } from "@/stores/orders.store"
import { storeToRefs } from "pinia"

export default {
    name: "OrderComments",
    props: {
        Comments: Object,
        OrderId: Number,
    },
    mounted() {
        this.comments = this.Comments
        this.order_id = this.OrderId
        const { isLoading } = storeToRefs(useOrderStore())
        this.loading = isLoading
        document.getElementById("save-btn").focus()
    },
    data() {
        return {
            new_comment: '',
            comments: [],
            loading: '',
        }
    },
    methods: {
        SaveComment() {
            const { isLoading, addOrderComment } = useOrderStore()
            this.loading = isLoading
            addOrderComment(this.new_comment, this.OrderId)
            this.$emit('UpdateComments')
        },
        CloseDialog() {
            this.$emit('UpdateComments')
        },
    },

}
</script>

<style scoped>
.order-comment {
    min-height: 400px;
}

.add-comment-wrap {
    height: auto;
}

.save-button {
    margin-top: -12px;
}

.row-comments {
    padding: 10px;
    text-align: justify;
    margin-right: 20px;
}
</style>



