<script setup>
import { storeToRefs } from "pinia";
import { useOrderStore } from "@/stores/orders.store";
import OrderCard from "@/components/OrderCard.vue";
import { ref, onMounted, watch, } from "vue";
import { onBeforeRouteUpdate, useRoute, useRouter } from "vue-router";

const { orders, isLoading, pageTotal, orderTotalCount } = storeToRefs(useOrderStore());
const { fetchOrderByPage } = useOrderStore();
const route = useRoute()
const router = useRouter()

let page = ref(route.params.number)
let action = ref(route.params.action)

onMounted(() => {
    fetchOrderByPage(action.value, page.value)
})

watch(page, (newPage, oldPage) => {
    if (newPage != oldPage) {
        router.push({ path: `/orders/${action.value}/page/${newPage}` })
    }
});

onBeforeRouteUpdate((to, from, next) => {
    page.value = to.params.number
    action.value = to.params.action
    fetchOrderByPage(action.value, page.value)
    next()
})
</script>

<template>
    <v-pagination v-if="pageTotal > 1" density="compact" show-first-last-page="true" color="indigo" :model-value="page"
        v-model="page" :total-visible="6" :length="pageTotal">
    </v-pagination>
    <v-row v-if="orderTotalCount > 0" justify="left" class="mb-2">
        <v-col>
            <div class="text-subtitle-1">Всего заказов:
                <b>{{ orderTotalCount }}</b>
            </div>
        </v-col>
    </v-row>
    <v-row v-if="isLoading" justify="center" class="mt-6">
        <v-progress-circular :size="70" :width="7" indeterminate color="primary"></v-progress-circular>
    </v-row>
    <v-container fluid>
        <v-row v-if="Array.isArray(orders) && orders.length">
            <v-col v-for="card in orders" :key="card" cols="12" md="6" xl="4">
                <OrderCard :oneOrder="card" />
            </v-col>
        </v-row>
    </v-container>
</template>