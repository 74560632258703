<script setup>
import { storeToRefs } from "pinia";
import { useSnackBarStore } from "@/stores/snackbar.store";

const snackBarStore = useSnackBarStore();
const { snackbar } = storeToRefs(snackBarStore);
</script>

<template>
    <v-snackbar content-class="mb-16" v-model="snackbar.visible" :timeout="snackbar.timeout" auto-height
        :color="snackbar.color" transition="fab-transition">
        <v-icon start :icon="snackbar.icon"></v-icon>
        {{ snackbar.title }} : {{ snackbar.text }}
        <template v-slot:actions>
            <v-btn color="white" variant="text" @click="snackBarStore.clear()">
                Close
            </v-btn>
        </template>
    </v-snackbar>
</template>